import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from '.';

interface IModalContext {
    showErrorModal: (_error: unknown) => void;
    showModal: (_title: string, _content: string) => void;
}

const ModalContext = React.createContext({
    showErrorModal: (_error: unknown) => {
        return;
    },
    showModal: (_title: string, _content: string) => {
        return;
    },
});

export const ModalProvider: React.FC = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const intl = useIntl();

    const showModal = (title: string, content: string) => {
        setTitle(title);
        setContent(content);
        setIsVisible(true);
    };

    return (
        <ModalContext.Provider
            value={{
                showModal,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                showErrorModal: (err: any) => {
                    if (err.code === 4001) {
                        showModal(
                            intl.formatMessage({
                                id: 'error.transactionRejectedTitle',
                            }),
                            intl.formatMessage({
                                id: 'error.transactionRejected',
                            })
                        );
                        return;
                    }
                    showModal(
                        intl.formatMessage({
                            id: 'error.unhandledTitle',
                        }),
                        err.message
                    );
                    setTitle(title);
                    setContent(content);
                    setIsVisible(true);
                },
            }}
        >
            <Modal
                title={title}
                content={content}
                isVisible={isVisible}
                close={() => setIsVisible(false)}
            />
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = (): IModalContext => {
    const ctx = useContext(ModalContext);

    if (!ctx) {
        throw new Error(
            'Component beyond modal state context, please wrap component'
        );
    }

    return ctx;
};
