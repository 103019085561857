export const en = {
    connectToWallet: 'Connect to a wallet',
    createRound: 'Create round',
    listOfPrivateRounds: 'List of rounds',
    open: 'Open',
    close: 'Close',
    active: 'Active',
    closedSettled: 'Closed Settled',
    closedNotSettled: 'Closed not settled',
    showWhiteList: 'Show whitelist',
    confirmationCloseRound: 'Are you sure you want to close the round ?',
    addInvestor: 'Add investor',
    sign: 'Sign',
    cancel: 'Cancel',
    amountToken: 'Amount of tokens',
    choosePayment: 'Choose payment',
    tokenPrice: 'Token price',
    endDate: 'End date',
    liquidityPool: 'Liquidity pool',
    currency: 'Currency',
    percentInLiquidity: 'Percentage of the amount allocated in liquidity',
    vesting: 'Vesting',
    purchaseToken: 'Purchase token',
    purchaseTokenPercent: 'Percentage of the amount purchase token',
    amountMonth: 'Amount of months',
    months: 'Months',
    numberOfMonthsvestign: 'Amount of months when investor dont get token',
    linearDistributedToken:
        'Amount of months where token are distributed linear',
    successDeployContract:
        'Contract was deployed Succesfull on address: {address}',
    FailDeployContract: 'Deploy was failed, error: {error}',
    transactionSubmitted: 'Transaction submitted',
    name: 'Name',
    company: 'Company',
    email: 'Email',
    phoneNumber: 'Phone number',
    walletAddress: 'Wallet address',
    hardCap: 'Hard Cap',
    investor: 'Investor',
    Unauthorized: 'Unauthorized',
    tokenRatio: 'Token Ratio',
    closingDate: 'Closing date',
    immediateRelease: 'Immediate release %',
    switchNetwork: 'Switch network',
    'error.transactionRejected': 'Please confirm transaction to proceed',
    'error.transactionRejectedTitle': 'Transaction rejected',
    'error.unhandledTitle': 'Unhandled error',
};
