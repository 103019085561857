import { IConfig } from '../../config/context';
import { deleteAuthToken } from '../../utils/auth';
import { ApiRequestService } from './service/ApiRequestService';
import {
    actionTypes,
    EWalletAction,
    IInitialState,
    ILoginDispatch,
    ILogoutDispatch,
    ITransactionPending,
    ITransactionPendingDispatch,
    IUpdateRoleDispatch,
} from './types/reducer';
import { getCurrentWalletData } from './utiles/wallet';

export const initialState: IInitialState = {
    isConnected: false,
    isValidNetwork: false,
    isAdmin: false,
    address: '',
    provider: null,
    signer: null,
    networkId: 0,
    transactionsPending: [],
    owners: [],
};

export const walletReducer = (
    draft: IInitialState,
    action: actionTypes
): void => {
    switch (action.type) {
        case EWalletAction.LOGIN:
            {
                draft.address = action.payload.address;
                draft.isConnected = action.payload.isConnected;
                draft.isValidNetwork = action.payload.isValidNetwork;
                draft.provider = action.payload.provider;
                draft.networkId = action.payload.networkId;
                draft.signer = action.payload.signer;
            }
            break;
        case EWalletAction.LOGOUT:
            {
                draft.address = initialState.address;
                draft.isConnected = initialState.isConnected;
                draft.isValidNetwork = initialState.isValidNetwork;
                draft.provider = initialState.provider;
                draft.networkId = initialState.networkId;
                draft.signer = initialState.signer;
                draft.isAdmin = initialState.isAdmin;
            }
            break;
        case EWalletAction.UPDATE_ROLE:
            {
                draft.isAdmin = action.payload.isAdmin;
            }
            break;
        case EWalletAction.TRANSACTION_PENDING:
            {
                draft.transactionsPending = action.payload.transactions;
            }
            break;
    }
};

export const walletLogin = async (config: IConfig): Promise<ILoginDispatch> => {
    try {
        const { address, networkId, signer, provider } =
            await getCurrentWalletData();

        if (!address) {
            await window?.ethereum?.request({ method: 'eth_requestAccounts' });
            throw new Error('No wallet connected');
        }

        const isValidNetwork = Number(config.networkId) === networkId;

        const data = {
            isConnected: true,
            isValidNetwork,
            address,
            provider,
            signer,
            networkId,
        };
        return { type: EWalletAction.LOGIN, payload: data };
    } catch (err) {
        console.error(err); // todo emit error displaying
        return { type: EWalletAction.LOGIN, payload: initialState };
    }
};

export const logout = (): ILogoutDispatch => {
    deleteAuthToken();
    return { type: EWalletAction.LOGOUT };
};

export const getAccountInfo = async (): Promise<
    ILogoutDispatch | IUpdateRoleDispatch
> => {
    try {
        const response = await ApiRequestService.refreshToken();
        return {
            type: EWalletAction.UPDATE_ROLE,
            payload: { isAdmin: !!response },
        };
    } catch (err) {
        console.warn(err);
        deleteAuthToken();
        return { type: EWalletAction.LOGOUT };
    }
};

export const loadTransactionPending =
    async (): Promise<ITransactionPendingDispatch> => {
        const transactions = await localStorage.getItem('transactionPending');
        if (!transactions) {
            return {
                type: EWalletAction.TRANSACTION_PENDING,
                payload: { transactions: [] },
            };
        }

        const parsed = JSON.parse(transactions);
        return {
            type: EWalletAction.TRANSACTION_PENDING,
            payload: { transactions: parsed },
        };
    };

export const deleteTransactionFromPending = (
    transactions: ITransactionPending[],
    hash: string
): ITransactionPendingDispatch => {
    const newTransactions = transactions.filter((el) => el.hash !== hash); // nosemgrep
    localStorage.setItem('transactionPending', JSON.stringify(newTransactions));
    return {
        type: EWalletAction.TRANSACTION_PENDING,
        payload: { transactions: newTransactions },
    };
};

export const addTransactionPending = (
    transactions: ITransactionPending[],
    transaction: ITransactionPending
): ITransactionPendingDispatch => {
    const newTransactions = [...transactions, transaction];
    localStorage.setItem('transactionPending', JSON.stringify(newTransactions));
    return {
        type: EWalletAction.TRANSACTION_PENDING,
        payload: { transactions: newTransactions },
    };
};
