import axios from 'axios';
import { isAddress } from 'ethers/lib/utils';

interface IToken {
    token: string;
    expire: string;
}

type Response = { nonce: string; address: string };

class RequestService {
    async getNonce(address: string): Promise<string | null> {
        if (!isAddress(address)) {
            return null;
        }

        const body = await axios.get<Response>(`auth/${address}`);
        return body.data.nonce;
    }

    async postAuthWithSignedMessage(
        address: string,
        signedMessage: string
    ): Promise<string> {
        const body = await axios.post<IToken>('auth/', {
            publicKey: address,
            sig: signedMessage,
        });
        return body.data.token;
    }

    async refreshToken() {
        const body = await axios.get<IToken>(`auth/refresh_token`);
        return body.data.token;
    }
}

export const ApiRequestService = new RequestService();
