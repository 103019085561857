import Safe from '@gnosis.pm/safe-core-sdk';
import { IQuorum } from '../domain/admin/types/quorum';
import { ETransactionStatus } from '../domain/wallet/types/transactionStatus';

export const isConfirmedAndExecuted = async <
    T extends {
        txIndex: number;
        txAddress: string;
        quorum: IQuorum[];
        isConfirmed: boolean;
        executed: boolean;
        status: ETransactionStatus;
    }
>(
    list: T[],
    contract: Safe
): Promise<T[]> => {
    const owners = await contract.getOwners();
    const numConfirmationRequired = await contract.getThreshold();

    return await Promise.all(
        list.map(async (el) => {
            const whoSigned = await contract.getOwnersWhoApprovedTx(
                el.txAddress
            );

            return {
                ...el,
                quorum: owners.map((address) => ({
                    address,
                    signed: whoSigned.includes(address),
                })),
                isConfirmed: whoSigned.length >= numConfirmationRequired,
                executed: el.status === ETransactionStatus.SUCCESS,
            };
        })
    );
};
