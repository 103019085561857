import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { WalletStateContext } from '../../../wallet/context';

export const PrivateRoute = ({
    children,
}: {
    children: JSX.Element;
}): JSX.Element => {
    const { isAdmin } = useContext(WalletStateContext);

    return isAdmin ? children : <Navigate to="/" />;
};
