const errors = [4001] as const;
type TError = typeof errors[number];

export class EthersError extends Error {
    constructor(message: string, public readonly code: TError) {
        super(message);
    }
}

export const isEthersError = (error: unknown): error is EthersError => {
    return errors.includes((error as EthersError).code);
};
