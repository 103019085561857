/* istanbul ignore file */
import { ethers } from 'ethers';
export class ContractFactory {
    private instanceEther: ethers.ContractFactory;
    constructor(
        private readonly abi: ethers.ContractInterface,
        private readonly byteCode: ethers.utils.BytesLike,
        private readonly signer: ethers.Signer
    ) {
        this.instanceEther = new ethers.ContractFactory(
            this.abi,
            this.byteCode,
            this.signer
        );
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public async deploy(...args: any[]): Promise<ethers.Contract> {
        return this.instanceEther.deploy(...args);
    }
}
