import React, { useContext, useEffect, useState } from 'react';

export interface IConfig {
    networkId: string;
    MULTISIG: string;
    DPX_TOKEN: string;
    TOKEN_A: string;
    TOKEN_B: string;
    ADDRESS: string;
}

const ConfigStateProvider = React.createContext<IConfig | undefined>(undefined);

export const ConfigProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<IConfig>({
        networkId: '',
        MULTISIG: '',
        DPX_TOKEN: '',
        TOKEN_A: '',
        TOKEN_B: '',
        ADDRESS: '',
    });
    useEffect(() => {
        (async () => {
            try {
                await fetch(
                    `${
                        process.env.REACT_APP_CONFIG_URL ||
                        'http://localhost:3000'
                    }/config.json`
                ).then(async (response) => {
                    const data = await response.json();

                    setState({
                        networkId: data.REACT_APP_NETWORK_ID,
                        MULTISIG: data.REACT_APP_MULTISIG,
                        DPX_TOKEN: data.REACT_APP_DPX_TOKEN,
                        TOKEN_A: data.REACT_APP_TOKEN_A,
                        TOKEN_B: data.REACT_APP_TOKEN_B,
                        ADDRESS: data.REACT_APP_ADDRESS,
                    });
                });
            } catch (err) {
                console.log('err', err);
            }
        })();
    }, []);
    return (
        <ConfigStateProvider.Provider value={state}>
            {children}
        </ConfigStateProvider.Provider>
    );
};

export const useConfig = (): IConfig => {
    const ctx = useContext(ConfigStateProvider);

    if (!ctx) {
        throw new Error(
            'Component beyond Config state context, please connect'
        );
    }

    return ctx;
};
