import { Button, Space, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { sliceAddress } from '../../../../utils/address';
import { WalletDispatchContext, WalletStateContext } from '../../context';
import { loadTransactionPending } from '../../reducer';
import styles from './wallet.module.scss';

export const Wallet: React.FC = () => {
    const { address, transactionsPending } = useContext(WalletStateContext);
    const dispatch = useContext(WalletDispatchContext);

    useEffect(() => {
        (async () => dispatch(await loadTransactionPending()))();
    }, []);

    return (
        <div className={styles.wallet}>
            <Space>
                <div id="walletAddress" className={styles.address}>
                    {sliceAddress(address)}
                </div>
            </Space>
            {!!transactionsPending.length && (
                <>
                    <Button type="primary" ghost shape="round">
                        <Space>
                            {transactionsPending.length} pending{' '}
                            <Spin size="small" />
                        </Space>
                    </Button>
                </>
            )}
        </div>
    );
};
