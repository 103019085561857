import { ethers } from 'ethers';

export enum EWalletAction {
    LOGIN,
    LOGOUT,
    CHECK_USER,
    TRANSACTION_PENDING,
    UPDATE_ROLE,
}

export interface IInitialState {
    isConnected: boolean;
    isValidNetwork: boolean;
    isAdmin: boolean;
    address: string;
    provider: Nullable<ethers.providers.Web3Provider>;
    signer: Nullable<ethers.Signer>;
    networkId: number;
    transactionsPending: ITransactionPending[];
    owners: string[];
}

export interface ITransactionPending {
    hash: string;
    date: number;
    message: string;
}

export interface ILoginDispatch {
    type: EWalletAction.LOGIN;
    payload: Pick<
        IInitialState,
        | 'address'
        | 'isConnected'
        | 'isValidNetwork'
        | 'provider'
        | 'networkId'
        | 'signer'
    >;
}

export interface ILogoutDispatch {
    type: EWalletAction.LOGOUT;
}

export interface ITransactionPendingDispatch {
    type: EWalletAction.TRANSACTION_PENDING;
    payload: {
        transactions: ITransactionPending[];
    };
}

export interface IUpdateRoleDispatch {
    type: EWalletAction.UPDATE_ROLE;
    payload: {
        isAdmin: boolean;
    };
}

export type actionTypes =
    | ILoginDispatch
    | ILogoutDispatch
    | ITransactionPendingDispatch
    | IUpdateRoleDispatch;
