import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../../config/context';
import {
    PrivateListDispatchContext,
    PrivateListStateContext,
} from '../../domain/admin/context';
import { IInvestor } from '../../domain/admin/types/investor';

import { WalletStateContext } from '../../domain/wallet/context';
import { sliceAddress } from '../../utils/address';

const { Column } = Table;

export const Investors: React.FC = () => {
    const [search, setSearch] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const { investor, privateRoundList, investorLoading } = useContext(
        PrivateListStateContext
    );
    const { loadInvestor, loadList } = useContext(PrivateListDispatchContext);
    const { signer } = useContext(WalletStateContext);
    const location = useLocation();
    const config = useConfig();

    useEffect(() => {
        if (!investor.length && signer && config.TOKEN_A) {
            (async () => {
                await loadList(signer);
                await loadInvestor(signer);
            })();
        }
    }, [signer, config]);

    const handleSearch = (
        selectedKeys: string,
        confirm: () => void,
        dataIndex: string
    ) => {
        confirm();
        setSearch({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: /* eslint-disable  @typescript-eslint/no-explicit-any */
        any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            setSearch((state) => ({
                                searchText: '',
                                searchedColumn: state.searchedColumn,
                            }));
                            confirm({ closeDropdown: true });
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (
            value: string | number | boolean,
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            record: Record<string, any>
        ) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(String(value).toLowerCase())
                : '',

        render: (text: string) => {
            return search.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[search.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            );
        },
    });

    const getList = useMemo(() => {
        const mappedInvestor = investor.map((el) => {
            const roundname = privateRoundList.find(
                (round) => round.id === el.roundId
            )?.name;

            return { ...el, roundName: roundname || '' };
        });
        if (location.state) {
            return mappedInvestor.filter((el) => el.roundId === location.state);
        }

        return mappedInvestor;
    }, [location.state, investor, privateRoundList]);

    return (
        <>
            <Table dataSource={getList} loading={investorLoading}>
                <Column<IInvestor> // nosemgrep
                    title="Round Name"
                    key="roundName"
                    dataIndex="roundName"
                    align="center"
                    {...getColumnSearchProps('roundName')}
                    sorter={(a, b) => a.roundName.localeCompare(b.roundName)}
                />

                <Column<IInvestor> // nosemgrep
                    title="Name"
                    key="name"
                    dataIndex="name"
                    align="center"
                    {...getColumnSearchProps('name')}
                    sorter={(a, b) => a.name.localeCompare(b.name)}
                />

                <Column<IInvestor> // nosemgrep
                    title="Company"
                    key="company"
                    dataIndex="company"
                    align="center"
                    {...getColumnSearchProps('company')}
                    sorter={(a, b) => {
                        const firstCompany = a.company || '';
                        const secondCompany = b.company || '';
                        return firstCompany.localeCompare(secondCompany);
                    }}
                />
                <Column<IInvestor> // nosemgrep
                    title="Email"
                    key="email"
                    dataIndex="email"
                    align="center"
                    {...getColumnSearchProps('email')}
                    sorter={(a, b) => a.email.localeCompare(b.email)}
                />
                <Column<IInvestor> // nosemgrep
                    title="Phone"
                    key="phone"
                    dataIndex="phone"
                    align="center"
                    {...getColumnSearchProps('phone')}
                />
                <Column<IInvestor> // nosemgrep
                    title="Hard Cap"
                    key="hardCap"
                    dataIndex="hardCap"
                    align="center"
                    {...getColumnSearchProps('hardCap')}
                    sorter={(a, b) => a.hardCap - b.hardCap}
                    render={(el, row) => {
                        return <Space>{row.hardCap}</Space>;
                    }}
                />
                <Column<IInvestor> // nosemgrep
                    title="Wallet Address"
                    key="walletAddress"
                    dataIndex="walletAddress"
                    align="center"
                    {...getColumnSearchProps('walletAddress')}
                    render={(address) => sliceAddress(address)}
                />
            </Table>
        </>
    );
};
