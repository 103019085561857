/* istanbul ignore file */

import { BigNumber, ContractTransaction, ethers } from 'ethers';
import { abi } from './PaypoolV1Crowdsale.json';
import { PaypoolV1Crowdsale } from './types';

export class CrowdSale {
    private instanceContract: PaypoolV1Crowdsale;
    constructor(
        public readonly contractAddress: string,
        public readonly signer: ethers.Signer
    ) {
        this.instanceContract = new ethers.Contract(
            this.contractAddress,
            abi,
            this.signer
        ) as PaypoolV1Crowdsale;
    }

    public async weiRaised(): Promise<string | BigNumber> {
        try {
            return await this.instanceContract.weiRaised();
        } catch {
            return '0';
        }
    }

    public async setWhitelistedWithCap(
        address: string,
        amount: BigNumber | string
    ): Promise<ContractTransaction> {
        return this.instanceContract.setWhitelistedWithCap(address, amount);
    }
}
