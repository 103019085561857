/* istanbul ignore file */
import axios from 'axios';
import React, { useContext, useMemo } from 'react';
import { WalletDispatchContext } from '../domain/wallet/context';
import { logout } from '../domain/wallet/reducer';
import { ApiRequestService } from '../domain/wallet/service/ApiRequestService';
import { getAuthToken, saveAuthToken } from '../utils/auth';

const WithAxios: React.FC = ({ children }) => {
    const dispatch = useContext(WalletDispatchContext);
    useMemo(() => {
        axios.interceptors.request.use(
            (config) => {
                if (!config.headers) {
                    config.headers = {};
                }
                config.baseURL =
                    process.env.REACT_APP_BASE_URL || 'http://localhost:3000';
                const token = getAuthToken();
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                const hit = Number(localStorage.getItem(config.url || ''));
                if (!hit) {
                    localStorage.setItem(config.url || '', '1');
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                localStorage.removeItem(response.config.url || '');
                return response;
            },
            async (error) => {
                const prevRequest = error.config;
                const hit = Number(localStorage.getItem(error.config.url));

                if (
                    error?.response.status === 401 &&
                    !prevRequest?.sent &&
                    hit === 1
                ) {
                    prevRequest.sent = true;
                    localStorage.setItem(
                        error.config.url || '',
                        String(hit + 1)
                    );

                    const newToken = await ApiRequestService.refreshToken();
                    saveAuthToken(newToken);

                    return axios(prevRequest);
                }
                dispatch(logout());
                setTimeout(
                    () => localStorage.removeItem(error.config.url || ''),
                    1000
                );
                return Promise.reject(error);
            }
        );
    }, []);

    return <div>{children}</div>;
};

export default WithAxios;
