import React, { Dispatch, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useConfig } from '../../config/context';
import {
    getAccountInfo,
    initialState,
    walletLogin,
    walletReducer,
} from './reducer';
import { actionTypes } from './types/reducer';
import { fetchAuthorizationToken } from './utiles/auth';
import { getCurrentWalletData } from './utiles/wallet';

export const WalletStateContext = React.createContext(initialState);
export const WalletDispatchContext = React.createContext<Dispatch<actionTypes>>(
    () => ({})
);

export const WalletProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useImmerReducer(walletReducer, initialState);
    const config = useConfig();

    // verify on provider mount if user is connected and verify authorization token
    useEffect(() => {
        (async () => {
            // verify if wallet is connected
            const { address } = await getCurrentWalletData();
            if (address) {
                dispatch(await walletLogin(config));
            }
        })();
    }, [config]);

    useEffect(() => {
        (async () => {
            if (state.address && state.isValidNetwork && state.provider) {
                dispatch(await walletLogin(config));
                await fetchAuthorizationToken(state.address);
                dispatch(await getAccountInfo());
            }
        })();
    }, [state.address, state.isValidNetwork, config]);

    return (
        <WalletDispatchContext.Provider value={dispatch}>
            <WalletStateContext.Provider value={state}>
                {children}
            </WalletStateContext.Provider>
        </WalletDispatchContext.Provider>
    );
};
