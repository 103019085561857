import {
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Progress,
    Space,
} from 'antd';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ApiRequestService } from '../../domain/admin/service/ApiRequestService';
import { useModalContext } from '../../domain/shared/components/modal/context';
import { WalletStateContext } from '../../domain/wallet/context';
import { ContractFactory } from '../../domain/wallet/contracts/ContractFactory';
import er20abi from '../../domain/wallet/contracts/erc20.json';
import { InterfaceService } from '../../domain/wallet/contracts/InterfaceService';

import {
    abi,
    bytecode,
} from '../../domain/wallet/contracts/PaypoolV1Crowdsale.json';
import { ETransactionStatus } from '../../domain/wallet/types/transactionStatus';
import { formValidation } from '../../utils/formValidation';
import { notification } from '../../utils/notification';

import { SafeTransactionDataPartial } from '@gnosis.pm/safe-core-sdk-types';
import { BigNumber, ethers, utils } from 'ethers';
import EthersAdapter from '@gnosis.pm/safe-ethers-lib';
import Safe from '@gnosis.pm/safe-core-sdk';
import { PrivateListStateContext } from '../../domain/admin/context';
import { useConfig } from '../../config/context';
import { Erc20Service } from '../../domain/wallet/contracts/Erc20Service';
import { formatEther } from 'ethers/lib/utils';

interface ICrowdsaleForm {
    amountToken: number;
    currency: string;
    endDate: Moment;
    id: number | string;
    price: number;
    amountMonthforPurchased: number;
    purchaseToken: number;
    vestingCliff: number;
    name: string;
}

export enum EStatusTx {
    AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
    EXECUDED = 'EXECUDED',
}

export const CreateRound: React.FC = () => {
    const [sending, setSending] = useState(false);
    const [maxAvailableToken, setMaxAvailableToken] = useState(0);
    const [progess, setProgress] = useState(0);
    const [infoProgress, setInfoProgress] = useState('');
    const { signer, provider } = useContext(WalletStateContext);
    const { privateRoundList } = useContext(PrivateListStateContext);
    const { showErrorModal } = useModalContext();
    const config = useConfig();
    const navigate = useNavigate();

    const intl = useIntl();
    const [form] = Form.useForm();

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const handleDisableDate = (date: any) => {
        return date && date.valueOf() < Date.now();
    };

    const checkAmount = async () => {
        if (!signer || !provider || !config.MULTISIG) {
            return;
        }

        const erc20 = new Erc20Service(config.DPX_TOKEN, signer);
        const amount = await erc20.balanceOf(config.MULTISIG);
        const formatted = formatEther(amount);

        setMaxAvailableToken(+formatted);
    };

    useEffect(() => {
        checkAmount();
    }, [provider, config]);

    const handleError = (err: any) => {
        showErrorModal(err);
        notification('error', err);
        return;
    };

    const handleDeployContract = async (values: ICrowdsaleForm) => {
        setSending(true);
        if (!signer || !provider) {
            return;
        }

        try {
            const erc20TokenA = new Erc20Service(config.TOKEN_A, signer);
            const erc20TokenB = new Erc20Service(config.TOKEN_B, signer);
            const decimalA = await erc20TokenA.decimals();
            const decimalB = await erc20TokenB.decimals();
            console.log('decimals', decimalB);

            if (decimalA !== decimalB) {
                throw new Error('decimal in tokens are not the same');
            }
            let multiplier = '1';
            if (decimalB === 6) {
                multiplier = '1000000000000';
            }

            const contractFactory = new ContractFactory(abi, bytecode, signer);

            const owner = await provider.getSigner(0);
            const ethAdapter = new EthersAdapter({
                ethers,
                signer: owner,
            });

            const safeSdk: Safe = await Safe.create({
                ethAdapter: ethAdapter,
                safeAddress: config.MULTISIG,
            });
            const DpxAmount = utils
                .parseUnits(String(values.amountToken))
                .toString();

            const one = utils.parseUnits('1');
            const price = utils.parseUnits(String(values.price));
            const amount = utils.parseUnits(String(values.amountToken));

            const perToken = BigNumber.from(one).div(price);
            const capAmount = amount.div(perToken).div(multiplier).toString();
            const rate = utils
                .parseUnits(perToken.toString())
                .mul(multiplier)
                .toString();

            const vestStart = moment(values.endDate)
                .add(values.vestingCliff, 'days')
                .unix();

            const ownersGnosis = await safeSdk.getOwners();

            const contractPayload = {
                rate,
                cap: capAmount,
                cliff: values.vestingCliff * 24 * 60 * 60,
                wallet: config.ADDRESS,
                tgeAwardPercentage: values.purchaseToken,
                vestingAwardPercentage: 0,
                vestingStartTime: vestStart,
                cliffDuration: 0,
                vestingDuration: values.amountMonthforPurchased * 24 * 60 * 60,
                closingTime: values.endDate.unix(),
                token: config.DPX_TOKEN,
                fundingTokenA: config.TOKEN_A,
                fundingTokenB: config.TOKEN_B,
                multisigOwner: config.MULTISIG,
                whitelistAdmins: ownersGnosis,
            };

            // amountMonthForPurchased - it is days
            const restData = {
                amountMonthForPurchased: values.amountMonthforPurchased,
                purchaseToken: values.purchaseToken,
                amountToken: DpxAmount,
                currency: values.currency || 'USDT',
                endDate: new Date(values.endDate.format()),
                liquidity: 0,
                price: rate,
                txIndex: Number(0),
                status: ETransactionStatus.WAITING,
                vestingCliff: values.vestingCliff,
                name: values.name,
            };
            setInfoProgress('Please confirm crowdsale deploy');
            const recipe = await contractFactory.deploy(contractPayload);
            setProgress(20);
            setInfoProgress('Please wait for deploing crowdsale');
            await recipe.deployTransaction.wait();
            setProgress(40);
            const erc20t = new InterfaceService(er20abi);
            const byteCodeErc20 = await erc20t.encodeFunctionData('transfer', [
                recipe.address,
                DpxAmount,
            ]);

            const tx: SafeTransactionDataPartial = {
                to: config.DPX_TOKEN,
                value: '0',
                data: byteCodeErc20,
                operation: 0,
            };

            setProgress(60);
            setInfoProgress('Preparing transaction');
            const safeTransaction = await safeSdk.createTransaction(tx);
            const txHash = await safeSdk.getTransactionHash(safeTransaction);
            setProgress(80);
            setInfoProgress('sending transaction to database');
            await ApiRequestService.sendRound({
                ...restData,
                deployedAddress: recipe.address,
                txAddress: txHash,
                txIndex: 999999999999,
            });
            setProgress(100);
            notification('success', 'transaction completed');
            navigate('/admin/list');
            form.resetFields();
            /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (err: any) {
            console.error(err);
            handleError(err);
        } finally {
            setSending(false);
        }
    };

    const canAddNewRound = () => {
        return privateRoundList.every(
            (el) => el.status === ETransactionStatus.SUCCESS
        );
    };

    return (
        <>
            <Form
                style={{ width: '60%', marginLeft: '13%' }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="nest-messages"
                onFinish={handleDeployContract}
                validateMessages={formValidation}
            >
                <Divider>Private Round General Set-up</Divider>
                <Form.Item
                    name="name"
                    label="Round name"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Round name" disabled={sending} />
                </Form.Item>
                <Form.Item
                    name="amountToken"
                    label="Amount of tokens"
                    rules={[
                        {
                            required: true,
                            type: 'number',
                            min: 1,
                            max: maxAvailableToken,
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Amount of tokens to be allocated to this round"
                        disabled={sending}
                        precision={0}
                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    label="Token Price"
                    rules={[
                        {
                            required: true,
                            type: 'number',
                            min: 0.00001,
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Price of each token in the currencies listed above"
                        disabled={sending}
                        precision={5}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    name="endDate"
                    label={intl.formatMessage({ id: 'closingDate' })}
                >
                    <DatePicker
                        disabledDate={handleDisableDate}
                        disabled={sending}
                    />
                </Form.Item>

                <br />
                <Divider>Vesting Set-up</Divider>
                <>
                    <Form.Item
                        name="purchaseToken"
                        label="Immediate release %"
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                min: 0,
                                max: 100,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Percentage of tokens that will be available for the participants on the closing date"
                            disabled={sending}
                            precision={0}
                        />
                    </Form.Item>
                    <Form.Item
                        name="amountMonthforPurchased"
                        label="Vesting time"
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Vesting time in days for the tokens to be released linearly over"
                            precision={0}
                            disabled={sending}
                        />
                    </Form.Item>
                    <Form.Item
                        name="vestingCliff"
                        label="Vesting cliff"
                        rules={[
                            {
                                required: true,
                                type: 'number',
                                min: 0,
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder="Vesting cliff in days"
                            precision={0}
                            disabled={sending}
                        />
                    </Form.Item>
                </>
                {!canAddNewRound() && (
                    <div style={{ textAlign: 'center' }}>
                        To add another round, you must first deploy the previous
                    </div>
                )}
                {canAddNewRound() && (
                    <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                        {sending ? (
                            <div className="centerX">
                                <Space>
                                    {/* <Spin size="large" /> */}
                                    <Progress
                                        type="circle"
                                        percent={progess}
                                        width={50}
                                    />
                                    {infoProgress}
                                </Space>
                            </div>
                        ) : (
                            <div className="centerX">
                                <Form.Item shouldUpdate className="submit">
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !form.isFieldsTouched(true) ||
                                                form
                                                    .getFieldsError()
                                                    .filter(
                                                        ({ errors }) =>
                                                            errors.length
                                                    ).length > 0
                                            }
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                        )}
                    </Form.Item>
                )}
            </Form>
        </>
    );
};
