import { PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import EthersAdapter from '@gnosis.pm/safe-ethers-lib';
import { Button, Modal, Space, Spin, Table, Tooltip } from 'antd';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AddInvestor } from '../../domain/admin/components/AddInvestor';
import { AddressWithSign } from '../../domain/admin/components/addressWithSign/AddressWithSign';
import { SignButtons } from '../../domain/admin/components/SignButtons';
import {
    PrivateListDispatchContext,
    PrivateListStateContext,
} from '../../domain/admin/context';
import { IPrivateRound } from '../../domain/admin/types/privateRound';
import { IQuorum } from '../../domain/admin/types/quorum';
import { useModalContext } from '../../domain/shared/components/modal/context';
import { WalletStateContext } from '../../domain/wallet/context';
import { isEthersError } from '../../utils/etherError';
import { formatDate } from '../../utils/formatDate';
import { notification } from '../../utils/notification';
import Safe from '@gnosis.pm/safe-core-sdk';
import { SafeTransactionDataPartial } from '@gnosis.pm/safe-core-sdk-types';
import { InterfaceService } from '../../domain/wallet/contracts/InterfaceService';
import er20abi from '../../domain/wallet/contracts/erc20.json';
import { ApiRequestService } from '../../domain/admin/service/ApiRequestService';
import { ETransactionStatus } from '../../domain/wallet/types/transactionStatus';
import { IInvestor } from '../../domain/admin/types/investor';
import { sliceAddress } from '../../utils/address';
import { useConfig } from '../../config/context';

const { Column } = Table;

export const ListRounds: React.FC = () => {
    const [signTx, setSignTx] =
        useState<
            Nullable<{ txAddress: string; quorum: IQuorum[]; rowId: number }>
        >(null);
    const [crowdSale, setCrowdSale] = useState<Nullable<IPrivateRound>>(null);
    const [loadingSignRound, setLoadingSignRound] = useState(false);
    const [loadingExecuteRound, setLoadingExecuteRound] = useState(false);
    const { loadList, signRound, executeRound, loadInvestor } = useContext(
        PrivateListDispatchContext
    );
    const { privateRoundList, investor, roundLoading } = useContext(
        PrivateListStateContext
    );
    const { signer, address } = useContext(WalletStateContext);
    const navigate = useNavigate();
    const config = useConfig();
    const { showErrorModal } = useModalContext();

    useEffect(() => {
        if (signer && config.TOKEN_A) {
            (async () => {
                await loadList(signer);
                await loadInvestor(signer);
            })();
        }
    }, [signer, config]);

    const handleClickTxIndex = (
        txAddress: Nullable<string>,
        quorum: IQuorum[],
        rowId: number
    ) => {
        if (!txAddress) {
            return;
        }
        setSignTx({ txAddress, quorum, rowId });
    };

    const handleClickSign = async () => {
        if (!signer || !signTx) {
            return;
        }
        setLoadingSignRound(true);

        try {
            const ethAdapter = new EthersAdapter({
                ethers,
                signer,
            });

            const safeSdk = await Safe.create({
                ethAdapter,
                safeAddress: config.MULTISIG,
            });

            const approveTxResponse = await safeSdk.approveTransactionHash(
                signTx.txAddress
            );
            await approveTxResponse.transactionResponse?.wait();

            notification('success', 'Crowdsale signed');
            signRound(signTx.txAddress, address);
        } catch (err: unknown) {
            showErrorModal(err);
        } finally {
            setSignTx(null);
            setLoadingSignRound(false);
        }
    };

    const handleClickExecute = async (txAddress: Nullable<string>) => {
        if (!signer || !txAddress) {
            return;
        }
        setLoadingExecuteRound(true);

        try {
            const ethAdapter = new EthersAdapter({
                ethers,
                signer,
            });

            const safeSdk = await Safe.create({
                ethAdapter,
                safeAddress: config.MULTISIG,
            });

            const round = privateRoundList.find(
                (el) => el.txAddress === txAddress
            );
            if (!round) {
                return;
            }

            const erc20t = new InterfaceService(er20abi);
            const byteCodeErc20 = await erc20t.encodeFunctionData('transfer', [
                round.deployedAddress,
                round.amountToken,
            ]);

            const tx: SafeTransactionDataPartial = {
                to: config.DPX_TOKEN,
                value: '0',
                data: byteCodeErc20,
                operation: 0,
            };
            const safeTransaction = await safeSdk.createTransaction(tx);
            const executeTxResponse = await safeSdk.executeTransaction(
                safeTransaction
            );
            await executeTxResponse.transactionResponse?.wait();

            await ApiRequestService.patchRounds({
                id: round.id,
                status: ETransactionStatus.SUCCESS,
            });

            executeRound(txAddress);

            notification('success', 'transaction executed successfully');
        } catch (err: unknown) {
            console.log('error', err);
            if (isEthersError(err)) {
                notification('error', err.message);
            }
        } finally {
            setLoadingExecuteRound(false);
        }
    };

    const handleClickAddInvestor = (address: Nullable<IPrivateRound>) => {
        setCrowdSale(address);
    };

    const handleClickWhiteList = (id: number) => {
        navigate('/admin/investors', { state: id });
    };

    const isSignedByMe = (): boolean => {
        return (
            signTx?.quorum.find((el) => el.address === address)?.signed || false
        );
    };

    const getInvestors = (id: Nullable<number>): IInvestor[] => {
        if (!id) {
            return [];
        }
        return investor.filter((el) => el.roundId === id);
    };

    return (
        <>
            {loadingExecuteRound && (
                <div
                    style={{
                        top: 0,
                        left: 0,
                        zIndex: 100000000,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin size="large" />
                </div>
            )}
            <Table dataSource={privateRoundList} loading={roundLoading}>
                <Column<IPrivateRound>
                    title="Name"
                    dataIndex="name"
                    key="name"
                    align="center"
                    render={(_, row) => (
                        <Space>
                            <span>{row.name}</span>

                            <Tooltip
                                overlayStyle={{
                                    whiteSpace: 'pre-line',
                                    maxWidth: '900px',
                                }}
                                placement="right"
                                title={`start Round: ${formatDate({
                                    date: row.startDate,
                                })}
                                end Round: ${formatDate({
                                    date: row.endDate,
                                })}
                                Token Price: ${
                                    1 /
                                    Number(
                                        formatUnits(
                                            row.price,
                                            row.decimal === 18 ? 18 : 30
                                        )
                                    )
                                }
                                liquidity pool: ${row.liquidity}
                                cliff time (days): ${row.vestingCliff}
                                vesting time (days): ${
                                    row.amountMonthForPurchased
                                }
                                Immediate release %: ${row.purchaseToken}
                                crowdsale contract: ${row.deployedAddress}
                                `}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>
                    )}
                />
                <Column<IPrivateRound>
                    title="Wallet status"
                    dataIndex="status"
                    key="status"
                    align="center"
                    render={(_, row) => (
                        <SignButtons
                            isConfirmed={row.isConfirmed}
                            executed={row.executed}
                            quorum={row.quorum}
                            txAddress={row.txAddress}
                            onClickTx={handleClickTxIndex}
                            onClickExecute={handleClickExecute}
                            address={address}
                            investors={getInvestors(row.id)}
                            rowId={row.id}
                            executeLoading={loadingExecuteRound}
                        />
                    )}
                />
                <Column<IPrivateRound>
                    title="Tokens"
                    key="tokenAmount"
                    align="center"
                    render={(_, row) => (
                        <Space>
                            <div key={row.name}>{`${row.tokenSold.toFixed(
                                0
                            )} / ${Number(formatEther(row.amountToken)).toFixed(
                                0
                            )}`}</div>
                        </Space>
                    )}
                />
                <Column<IPrivateRound>
                    title="End of round"
                    key="tokenAmount"
                    align="center"
                    render={(_, row) => formatDate({ date: row.endDate })}
                />
                <Column<IPrivateRound>
                    title="Whitelist"
                    align="center"
                    key="whiteList"
                    dataIndex="whiteList"
                    render={(el, row) => (
                        <>
                            <Space size="large">
                                <Button
                                    onClick={() => handleClickWhiteList(row.id)}
                                >
                                    {String(getInvestors(row.id).length)}
                                </Button>
                                <Button
                                    onClick={() => handleClickAddInvestor(row)}
                                >
                                    <PlusCircleOutlined
                                        style={{ fontSize: 20 }}
                                    />
                                </Button>
                            </Space>
                        </>
                    )}
                />
            </Table>

            <Modal
                visible={!!crowdSale}
                onCancel={() => handleClickAddInvestor(null)}
                width="50%"
                footer={[]}
            >
                {crowdSale?.deployedAddress && (
                    <AddInvestor
                        address={crowdSale.deployedAddress}
                        closeModal={setCrowdSale}
                        round={crowdSale.id}
                        price={crowdSale.price}
                        amountToken={Number(formatEther(crowdSale.amountToken))}
                    />
                )}
            </Modal>

            <Modal
                visible={!!signTx}
                onCancel={() => setSignTx(null)}
                width="90%"
                footer={[
                    <>
                        {!loadingSignRound ? (
                            <>
                                <Button
                                    key="cancel"
                                    onClick={() => setSignTx(null)}
                                    danger
                                    ghost
                                >
                                    <FormattedMessage id="cancel" />
                                </Button>
                                {!isSignedByMe() && (
                                    <Button
                                        key="sign"
                                        onClick={handleClickSign}
                                        type="primary"
                                        ghost
                                    >
                                        <FormattedMessage id="sign" />
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Spin size="large" />
                        )}
                    </>,
                ]}
            >
                {signTx?.quorum.map((el, i) => (
                    <AddressWithSign key={i} {...el} />
                ))}

                <h1 style={{ textAlign: 'center' }}>
                    All Investor for this round
                </h1>
                <Table
                    dataSource={getInvestors(signTx?.rowId || null)}
                    pagination={false}
                >
                    <Column<IInvestor> // nosemgrep
                        title="Name"
                        key="name"
                        dataIndex="name"
                        align="center"
                    />

                    <Column<IInvestor> // nosemgrep
                        title="Company"
                        key="company"
                        dataIndex="company"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Email"
                        key="email"
                        dataIndex="email"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Phone"
                        key="phone"
                        dataIndex="phone"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Hard Cap"
                        key="hardCap"
                        dataIndex="hardCap"
                        align="center"
                        render={(el, row) => {
                            return <Space>{row.hardCap}</Space>;
                        }}
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Wallet Address"
                        key="walletAddress"
                        dataIndex="walletAddress"
                        align="center"
                        render={(address) => sliceAddress(address)}
                    />
                </Table>
            </Modal>
        </>
    );
};
