export const saveAuthToken = (token: string): void => {
    localStorage.setItem('authToken', token);
};

export const deleteAuthToken = (): void => {
    localStorage.removeItem('authToken');
};

export const getAuthToken = (): string | null => {
    return localStorage.getItem('authToken');
};

export const getExpireToken = (): string | null => {
    return localStorage.getItem('expireToken');
};

export const isSavedAuthTokenValid = (): boolean => {
    return !!getAuthToken();
};
