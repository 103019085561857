import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import styles from './addressWithSign.module.scss';

interface IProps {
    address: string;
    signed: boolean;
}

export const AddressWithSign: React.FC<IProps> = ({ address, signed }) => {
    return (
        <div className={styles.wrap}>
            <div>
                {signed ? (
                    <CheckCircleOutlined
                        style={{ fontSize: '20px', color: 'green' }}
                    />
                ) : (
                    <CloseCircleOutlined
                        style={{ fontSize: '20px', color: 'red' }}
                    />
                )}
            </div>
            <div className={styles.address}>{address}</div>
        </div>
    );
};
