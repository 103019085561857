import { isSavedAuthTokenValid, saveAuthToken } from '../../../utils/auth';
import { ApiRequestService } from '../service/ApiRequestService';
import { signMessage } from './wallet';

export const fetchAuthorizationToken = async (
    address: string
): Promise<string | null> => {
    const nonce = await ApiRequestService.getNonce(address);
    if (!nonce) {
        return null;
    }
    const signedMessage = await signMessage(nonce);
    const token = await ApiRequestService.postAuthWithSignedMessage(
        address,
        signedMessage
    );

    if (!token) {
        return null;
    }

    saveAuthToken(token);

    return token;
};

export const refetchAuthorizationToken = async (
    address: string
): Promise<void> => {
    if (isSavedAuthTokenValid()) {
        return;
    }

    await fetchAuthorizationToken(address);
};
