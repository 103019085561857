import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WithAxios from './config/axios';
import { ConfigProvider } from './config/context';
import { PrivateListProvider } from './domain/admin/context';
import { ModalProvider } from './domain/shared/components/modal/context';
import { PrivateRoute } from './domain/shared/components/PrivateRoute';
import { LanguageProvider } from './domain/shared/translations';
import { WalletProvider } from './domain/wallet/context';
import { Admin } from './views/Admin';
import { CreateRound } from './views/Admin/CreateRound';
import { Investors } from './views/Admin/Investors';
import { ListRounds } from './views/Admin/ListRounds';
import { Home } from './views/Home';

console.log('-------', process.env.REACT_APP_BASE_URL);

export const App: React.FC = () => {
    return (
        <LanguageProvider>
            <ConfigProvider>
                <WalletProvider>
                    <WithAxios>
                        <ModalProvider>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/admin"
                                    element={
                                        <>
                                            <PrivateRoute>
                                                <PrivateListProvider>
                                                    <Admin />
                                                </PrivateListProvider>
                                            </PrivateRoute>
                                        </>
                                    }
                                >
                                    <Route
                                        path="round"
                                        element={<CreateRound />}
                                    />
                                    <Route
                                        path="investors"
                                        element={<Investors />}
                                    />

                                    <Route
                                        path="list"
                                        element={<ListRounds />}
                                    />
                                </Route>
                            </Routes>
                        </ModalProvider>
                    </WithAxios>
                </WalletProvider>
            </ConfigProvider>
        </LanguageProvider>
    );
};
