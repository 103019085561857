import dnt from 'date-and-time';

type TDateType = 'YYYY-MM-DD';

interface IProps {
    date: Date | string;
    format?: TDateType;
}

export const formatDate = ({ date, format = 'YYYY-MM-DD' }: IProps): string => {
    const dateToFormat = new Date(date);

    return dnt.format(dateToFormat, format);
};
