import { ethers } from 'ethers';
import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { IPrivateRound } from './types/privateRound';
import { ApiRequestService } from './service/ApiRequestService';
import { IInvestor } from './types/investor';
import { isConfirmedAndExecuted } from '../../utils/isCofirmedAndExecuted';
import { getCurrentWalletData } from '../wallet/utiles/wallet';
import { CrowdSale } from '../wallet/contracts/CrowdSaleService';
import { formatEther } from 'ethers/lib/utils';
import Safe from '@gnosis.pm/safe-core-sdk';
import EthersAdapter from '@gnosis.pm/safe-ethers-lib';
import { useConfig } from '../../config/context';
import { Erc20Service } from '../wallet/contracts/Erc20Service';

enum EListAction {
    PRIVATE_ROUND,
    INVESTOR,
    LOADING_ROUND,
    LOADING_INVESTOR,
}

interface ILoadingRound {
    type: EListAction.LOADING_ROUND;
}

interface ILoadingInvestor {
    type: EListAction.LOADING_INVESTOR;
}
interface IPrivateRoundPayload {
    type: EListAction.PRIVATE_ROUND;
    payload: {
        privateRoundList: IPrivateRound[];
    };
}

interface IInvestorPayload {
    type: EListAction.INVESTOR;
    payload: {
        investor: IInvestor[];
    };
}

interface IInitialState {
    privateRoundList: IPrivateRound[];
    roundLoading: boolean;
    investor: IInvestor[];
    investorLoading: boolean;
}

export type actionTypes =
    | IPrivateRoundPayload
    | IInvestorPayload
    | ILoadingRound
    | ILoadingInvestor;

const listReducer = (draft: IInitialState, action: actionTypes) => {
    switch (action.type) {
        case EListAction.PRIVATE_ROUND:
            {
                draft.privateRoundList = action.payload.privateRoundList;
            }
            break;

        case EListAction.INVESTOR:
            {
                draft.investor = action.payload.investor;
            }
            break;
        case EListAction.LOADING_ROUND:
            {
                draft.roundLoading = !draft.roundLoading;
            }
            break;

        case EListAction.LOADING_INVESTOR:
            {
                draft.investorLoading = !draft.investorLoading;
            }
            break;
    }
};

const initialState: IInitialState = {
    privateRoundList: [],
    investor: [],
    roundLoading: false,
    investorLoading: false,
};

export const PrivateListStateContext = createContext(initialState);
export const PrivateListDispatchContext = createContext({
    /* eslint-disable @typescript-eslint/no-unused-vars */
    loadList: async (signer: ethers.Signer) => {
        /* istanbul ignore next */
        return;
    },
    loadInvestor: async (signer: ethers.Signer) => {
        /* istanbul ignore next */
        return;
    },
    signRound: (txAddress: string, address: string) => {
        /* istanbul ignore next */
        return;
    },

    executeRound: (txAddress: string) => {
        /* istanbul ignore next */
        return;
    },
});

export const PrivateListProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useImmerReducer(listReducer, initialState);
    const { MULTISIG, TOKEN_A, TOKEN_B } = useConfig();

    const loadList = async (signer: ethers.Signer) => {
        dispatch({
            type: EListAction.LOADING_ROUND,
        });
        try {
            const round = await ApiRequestService.getRounds();
            const ethAdapter = new EthersAdapter({
                ethers,
                signer,
            });

            const safeSdk = await Safe.create({
                ethAdapter,
                safeAddress: MULTISIG,
            });
            const erc20TokenA = new Erc20Service(TOKEN_A, signer);
            const erc20TokenB = new Erc20Service(TOKEN_B, signer);
            const decimalA = await erc20TokenA.decimals();
            const decimalB = await erc20TokenB.decimals();

            if (decimalA !== decimalB) {
                throw new Error('decimal in tokens are not the same');
            }

            const listWithSign = await isConfirmedAndExecuted(round, safeSdk);

            const withHardCap = await Promise.all(
                listWithSign.map(async (el) => {
                    const crowdsale = new CrowdSale(el.deployedAddress, signer);

                    const weiRaised = await crowdsale.weiRaised();

                    const tokenSold =
                        Number(formatEther(weiRaised)) *
                        Number(formatEther(el.price));
                    return {
                        ...el,
                        tokenSold,
                        decimal: decimalB,
                    };
                })
            );

            await dispatch({
                type: EListAction.PRIVATE_ROUND,
                payload: {
                    privateRoundList: withHardCap.sort(
                        (a, b) =>
                            new Date(a.startDate).valueOf() -
                            new Date(b.startDate).valueOf()
                    ),
                },
            });
        } catch (err: unknown) {
            console.log(err);
        }
        dispatch({
            type: EListAction.LOADING_ROUND,
        });
    };

    const loadInvestor = async () => {
        dispatch({
            type: EListAction.LOADING_INVESTOR,
        });

        const investors = await ApiRequestService.getInvestor();

        await dispatch({
            type: EListAction.INVESTOR,
            payload: { investor: investors },
        });
        dispatch({
            type: EListAction.LOADING_INVESTOR,
        });
    };

    const signRound = async (txAddress: string, address: string) => {
        const { signer } = await getCurrentWalletData();

        const ethAdapter = new EthersAdapter({
            ethers,
            signer,
        });

        const safeSdk = await Safe.create({
            ethAdapter,
            safeAddress: MULTISIG,
        });

        const getThreshold = await safeSdk.getThreshold();

        const list = state.privateRoundList.map((el) => {
            const quorum = el.quorum.map((el) =>
                el.address === address ? { ...el, signed: true } : { ...el }
            );
            const isConfirmed =
                quorum.filter((el) => el.signed).length >= getThreshold;

            return txAddress === el.txAddress
                ? {
                      ...el,
                      quorum,
                      isConfirmed,
                  }
                : { ...el };
        });

        dispatch({
            type: EListAction.PRIVATE_ROUND,
            payload: { privateRoundList: list },
        });
    };

    const executeRound = (txAddress: string) => {
        const round = state.privateRoundList.map((el) =>
            el.txAddress === txAddress
                ? {
                      ...el,
                      executed: true,
                  }
                : {
                      ...el,
                  }
        );
        dispatch({
            type: EListAction.PRIVATE_ROUND,
            payload: { privateRoundList: round },
        });
    };

    return (
        <PrivateListStateContext.Provider value={state}>
            <PrivateListDispatchContext.Provider
                value={{
                    loadList,
                    loadInvestor,
                    signRound,
                    executeRound,
                }}
            >
                {children}
            </PrivateListDispatchContext.Provider>
        </PrivateListStateContext.Provider>
    );
};
