import { ethers } from 'ethers';

export const getProvider = (): ethers.providers.Web3Provider => {
    if (!window.ethereum) {
        throw new Error('No provider available');
    }
    return new ethers.providers.Web3Provider(window.ethereum);
};

export const getCurrentAddressFromProvider = async (): Promise<{
    address: string;
    networkId: number;
}> => {
    const provider = getProvider();
    const signer = provider.getSigner();
    const address = await signer?.getAddress();
    const networkId = (await provider.getNetwork()).chainId;

    return { address, networkId };
};

type GetCurrentWalletDataReturnData<T = string | null> = {
    signer: ethers.providers.JsonRpcSigner;
    address: T;
    networkId: number;
    provider: ethers.providers.Web3Provider;
};

export const getCurrentWalletData = async (): Promise<
    GetCurrentWalletDataReturnData<string | null>
> => {
    const provider = getProvider();
    const signer = provider.getSigner();
    const networkId = (await provider.getNetwork()).chainId;

    try {
        const address = await provider.getSigner().getAddress();
        return { signer, address, networkId, provider };
    } catch (err) {
        return { signer, address: null, networkId, provider };
    }
};

export const signMessage = async (message: string): Promise<string> => {
    const { signer } = await getCurrentWalletData();
    return signer.signMessage(message);
};
