import { Button, Modal as AntdModal } from 'antd';
import React from 'react';

const Modal: React.FC<{
    isVisible: boolean;
    title: string;
    content: string;
    close: () => void;
}> = ({ isVisible, content, title, close }) => {
    return (
        <AntdModal
            visible={isVisible}
            title={title}
            onCancel={close}
            closable={false}
            onOk={close}
            footer={[
                <Button key="ok" onClick={close}>
                    Ok
                </Button>,
            ]}
        >
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {content}
            </p>
        </AntdModal>
    );
};

export default Modal;
