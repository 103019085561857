import React, { useContext, useEffect, useState } from 'react';
import { Button, Layout, Menu, Space } from 'antd';
import {
    PlusOutlined,
    OrderedListOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';
import { Wallet } from '../../domain/wallet/components/wallet';
import Logo from '../../assets/logo.svg';
import { useLocation } from 'react-router-dom';
import { PrivateListDispatchContext } from '../../domain/admin/context';
import { WalletStateContext } from '../../domain/wallet/context';
import axios from 'axios';

const { Header, Content, Footer } = Layout;

const routeAnt = [
    { key: '1', path: '/admin/round' },
    { key: '2', path: '/admin/list' },
    { key: '3', path: '/admin/investors' },
];

export const Admin: React.FC = () => {
    const location = useLocation();
    const [keyMenu, setKeyMenu] = useState(['1']);
    const [backVersion, setBackVersion] = useState('');
    const [frontVersion, setFrontVersion] = useState('');
    const { loadInvestor, loadList } = useContext(PrivateListDispatchContext);
    const { signer } = useContext(WalletStateContext);

    useEffect(() => {
        const key =
            routeAnt.find((el) => el.path === location.pathname)?.key ?? '1';

        setKeyMenu([key]);
    }, [location.pathname]);

    const handleClickRefresh = async () => {
        if (signer) {
            await loadInvestor(signer);
            await loadList(signer);
        }
    };

    const loadBackVersion = async () => {
        try {
            const payload = await axios.get(
                'https://private-sale-core.paypool.pl/health'
            );
            setBackVersion(payload.data.version);
        } catch (err) {
            console.log(err);
        }
    };

    const loadFrontVersion = () => {
        const version = process.env.CF_PAGES_COMMIT_SHA || '';
        setFrontVersion(version);
    };

    useEffect(() => {
        loadBackVersion();
        loadFrontVersion();
    }, []);

    return (
        <Layout style={{ height: '100vh' }}>
            <Header
                style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#000',
                }}
            >
                <img src={Logo} width="250" style={{ margin: '0 20px' }} />
                <Menu
                    theme="dark"
                    style={{
                        backgroundColor: '#000',
                        flex: 'auto',
                    }}
                    mode="horizontal"
                    selectedKeys={keyMenu}
                >
                    <Menu.Item key="1" icon={<PlusOutlined />}>
                        <Link to="round">
                            <FormattedMessage id="createRound" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<OrderedListOutlined />}>
                        <Link to="list">
                            <FormattedMessage id="listOfPrivateRounds" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<TeamOutlined />}>
                        <Link to="investors">
                            <FormattedMessage id="investor" />
                        </Link>
                    </Menu.Item>
                </Menu>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <Button onClick={handleClickRefresh}>
                            refresh list
                        </Button>
                    </div>
                    <Wallet />
                </div>
            </Header>
            <Content style={{ margin: '24px 16px 0' }}>
                <Outlet />
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                <Space>
                    <span>Brightpool</span>
                    {frontVersion && <span>{`Fv: ${frontVersion}`}</span>}
                    {backVersion && <span>{`Bv: ${backVersion}`}</span>}
                </Space>
            </Footer>
        </Layout>
    );
};
