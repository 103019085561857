/* istanbul ignore file */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import { JsonFragment } from '@ethersproject/abi';
import { ethers } from 'ethers';
import { Fragment } from 'ethers/lib/utils';

export class InterfaceService {
    private instance: ethers.utils.Interface;
    constructor(
        public readonly abi:
            | string
            | ReadonlyArray<Fragment | JsonFragment | string>
    ) {
        this.instance = new ethers.utils.Interface(this.abi);
    }

    public async encodeFunctionData(
        name: string,
        values?: any[]
    ): Promise<string> {
        return await this.instance.encodeFunctionData(name, values);
    }
}
