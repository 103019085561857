import React, { useContext, useState } from 'react';
import { Button, Form, Input, InputNumber, Space, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { formValidation } from '../../../utils/formValidation';
import { isAddress } from '../../../utils/address';

import { WalletStateContext } from '../../wallet/context';
import {
    PrivateListDispatchContext,
    PrivateListStateContext,
} from '../context';
import { ApiRequestService } from '../service/ApiRequestService';
import { notification } from '../../../utils/notification';

import { ETransactionStatus } from '../../wallet/types/transactionStatus';
import { CrowdSale } from '../../wallet/contracts/CrowdSaleService';
import { BigNumber, utils } from 'ethers';

interface iProps {
    address: string;
    round: number;
    closeModal: (round: null) => void;
    price: string;
    amountToken: number;
}

interface IInvestorForm {
    company?: string;
    email: string;
    hardCap: number;
    name: string;
    phone: number;
    walletAddress: string;
}

export const AddInvestor: React.FC<iProps> = ({
    address,
    closeModal,
    round,
    price,
    amountToken,
}) => {
    const [sending, setSending] = useState(false);
    const { signer, provider } = useContext(WalletStateContext);
    const { loadInvestor } = useContext(PrivateListDispatchContext);
    const { investor } = useContext(PrivateListStateContext);
    const intl = useIntl();
    const [form] = Form.useForm();

    // 600 000 000 000 000

    const handleClickSubmit = async (values: IInvestorForm) => {
        if (!signer || !provider) {
            return;
        }
        try {
            setSending(true);
            const crowdsale = new CrowdSale(address, signer);

            const hardCap = utils.parseUnits(String(values.hardCap));
            const calcPrice = BigNumber.from(price).div('1000000000000000000');
            const cap = BigNumber.from(hardCap).div(calcPrice);

            const tx = await crowdsale.setWhitelistedWithCap(
                values.walletAddress,
                cap
            );

            await tx.wait();

            await ApiRequestService.sendInvestor({
                name: values.name,
                company: values.company || '',
                email: values.email,
                hardCap: values.hardCap,
                investmentRoundId: round,
                phoneNumber: String(values.phone),
                txAddress: '0x',
                txIndex: Number(99999999999999),
                walletAddress: values.walletAddress,
                status: ETransactionStatus.WAITING,
            });
            notification('success', 'Investor added successfully');

            loadInvestor(signer);
        } catch (err: unknown) {
            console.log('err', err);
        } finally {
            form.resetFields();
            setSending(false);
            closeModal(null);
        }
    };

    const getMaxValue = (): number => {
        let max = 0;
        investor
            .filter((el) => el.roundId === round)
            .forEach((el) => {
                max = el.hardCap + max;
            });
        return amountToken - max;
    };

    const isAddressExist = (address: string): boolean => {
        return investor
            .filter((el) => el.roundId === round)
            .some((el) => el.walletAddress === address);
    };

    return (
        <Form
            style={{ width: '60%', marginLeft: '13%' }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleClickSubmit}
            form={form}
            name="nest-messages"
            validateMessages={formValidation}
        >
            <Form.Item name="name" label="name" rules={[{ required: true }]}>
                <Input placeholder="name" disabled={sending} />
            </Form.Item>
            <Form.Item
                name="company"
                label={intl.formatMessage({ id: 'company' })}
            >
                <Input
                    placeholder={intl.formatMessage({ id: 'company' })}
                    disabled={sending}
                />
            </Form.Item>
            <Form.Item
                name="email"
                label={intl.formatMessage({ id: 'email' })}
                rules={[{ type: 'email', required: true }]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: 'email' })}
                    disabled={sending}
                />
            </Form.Item>

            <Form.Item
                name="phone"
                label={intl.formatMessage({ id: 'phoneNumber' })}
                rules={[{ required: true }]}
            >
                <InputNumber
                    placeholder={intl.formatMessage({ id: 'phoneNumber' })}
                    style={{ width: '100%' }}
                    disabled={sending}
                />
            </Form.Item>

            <Form.Item
                name="walletAddress"
                label={intl.formatMessage({ id: 'walletAddress' })}
                rules={[
                    { type: 'string', required: true },

                    {
                        validator: (_, value) =>
                            isAddress(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                      new Error('Wallet address is not valid')
                                  ),
                    },

                    {
                        validator: (_, value) =>
                            !isAddressExist(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                      new Error(
                                          'Wallet address already exist in this round'
                                      )
                                  ),
                    },
                ]}
            >
                <Input
                    placeholder={intl.formatMessage({ id: 'walletAddress' })}
                    disabled={sending}
                />
            </Form.Item>

            <Form.Item
                name="hardCap"
                label={intl.formatMessage({ id: 'hardCap' })}
                rules={[
                    { required: true },
                    {
                        validator: (_, value) => {
                            return value > getMaxValue()
                                ? Promise.reject(
                                      new Error(`max value is ${getMaxValue()}`)
                                  )
                                : Promise.resolve();
                        },
                    },
                ]}
            >
                <InputNumber
                    placeholder="max number of tokens"
                    style={{ width: '100%' }}
                    disabled={sending}
                />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 0, offset: 8 }}>
                {sending ? (
                    <div className="centerX">
                        <Spin size="large" />
                    </div>
                ) : (
                    <Space size="large">
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>

                        <Button
                            key="cancel"
                            onClick={() => closeModal(null)}
                            danger
                            ghost
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Form>
    );
};
