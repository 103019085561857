import { getRoundDTO } from '../types/DTO';
import { IPrivateRound } from '../types/privateRound';

export const privateRoundModel = (rounds: getRoundDTO[]): IPrivateRound[] => {
    return rounds.map((el) => ({
        key: el.id,
        id: el.id,
        name: el.name || '',
        round: `round ${el.id}`,
        endDate: el.endDate,
        startDate: el.createdAt,
        updateDate: el.updatedAt,
        amountToken: el.amountToken,
        tokenSold: 0,
        status: el.status,
        quorum: [],
        txIndex: el.txIndex,
        isConfirmed: false,
        executed: false,
        deployedAddress: el.deployedAddress,
        price: el.price,
        vestingCliff: el.vestingCliff,
        liquidity: el.liquidity,
        amountMonthForPurchased: el.amountMonthForPurchased,
        purchaseToken: el.purchaseToken,
        txAddress: el.txAddress,
        decimal: 0,
        crowdsaleContract: el.deployedAddress,
    }));
};
