import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { ConnectWallet } from '../../domain/wallet/components/ConnectWallet';
import { WalletStateContext } from '../../domain/wallet/context';
import classes from './home.module.scss';

export const Home: React.FC = () => {
    const { isAdmin } = useContext(WalletStateContext);

    return (
        <div className={classes.home}>
            {!isAdmin ? (
                <>
                    <ConnectWallet />
                    <main className={classes.landing}>
                        <h1>Landing Page</h1>
                    </main>
                </>
            ) : (
                <Navigate to="/admin/list" />
            )}
        </div>
    );
};
