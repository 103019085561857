import { Button, Modal, Space, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useState } from 'react';
import { sliceAddress } from '../../../utils/address';
import { IInvestor } from '../types/investor';
import { IQuorum } from '../types/quorum';
import { AddressWithSign } from './addressWithSign/AddressWithSign';

interface IProps {
    isConfirmed: boolean;
    executed: boolean;
    quorum: IQuorum[];
    txAddress: string;
    onClickTx: (txAddress: string, quorum: IQuorum[], rowId: number) => void;
    onClickExecute: (txAddress: string) => void;
    address: string;
    investors: IInvestor[];
    rowId: number;
    executeLoading: boolean;
}

export const SignButtons: React.FC<IProps> = ({
    isConfirmed,
    executed,
    quorum,
    txAddress,
    onClickTx,
    onClickExecute,
    investors,
    rowId,
    executeLoading,
}) => {
    const [preview, setPreview] = useState(false);

    const isSigned = (_quorum: IQuorum[]): boolean => {
        return _quorum.some((el) => el.signed);
    };

    const handleClickTxIndex = () => {
        onClickTx(txAddress, quorum, rowId);
    };

    const handleClickExecute = () => {
        onClickExecute(txAddress);
        setPreview(false);
    };
    return (
        <Space>
            {!isConfirmed && !isSigned(quorum) && (
                <Button danger key="2" onClick={handleClickTxIndex}>
                    Waiting for your signature
                </Button>
            )}
            {!isConfirmed && isSigned(quorum) && (
                <Button danger key="2" onClick={handleClickTxIndex}>
                    Waiting for quorum
                </Button>
            )}
            {isConfirmed && !executed && (
                <Button
                    type="primary"
                    ghost
                    key="1"
                    loading={executeLoading}
                    onClick={() => setPreview(true)}
                >
                    Click to add
                </Button>
            )}

            {executed && isConfirmed && (
                <Button
                    type="primary"
                    ghost
                    key="1"
                    onClick={handleClickTxIndex}
                    style={{ borderColor: '#389e0d', color: '#389e0d' }}
                >
                    Whitelisted
                </Button>
            )}
            <Modal
                visible={preview}
                onCancel={() => setPreview(false)}
                width="90%"
                footer={[]}
            >
                {quorum.map((el, i) => (
                    <AddressWithSign key={i} {...el} />
                ))}
                <h1 style={{ textAlign: 'center' }}>
                    All Investor for this round
                </h1>
                <Table dataSource={investors} pagination={false}>
                    <Column<IInvestor> // nosemgrep
                        title="Name"
                        key="name"
                        dataIndex="name"
                        align="center"
                    />

                    <Column<IInvestor> // nosemgrep
                        title="Company"
                        key="company"
                        dataIndex="company"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Email"
                        key="email"
                        dataIndex="email"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Phone"
                        key="phone"
                        dataIndex="phone"
                        align="center"
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Hard Cap"
                        key="hardCap"
                        dataIndex="hardCap"
                        align="center"
                        render={(el, row) => {
                            return <Space>{row.hardCap}</Space>;
                        }}
                    />
                    <Column<IInvestor> // nosemgrep
                        title="Wallet Address"
                        key="walletAddress"
                        dataIndex="walletAddress"
                        align="center"
                        render={(address) => sliceAddress(address)}
                    />
                </Table>
                <br />
                <br />

                <Button
                    type="primary"
                    ghost
                    key="1"
                    onClick={() => handleClickExecute()}
                >
                    Confirm
                </Button>
            </Modal>
        </Space>
    );
};
