import { getInvestorDTO } from '../types/DTO';
import { IInvestor } from '../types/investor';

export const investorModel = (investors: getInvestorDTO[]): IInvestor[] => {
    return investors.map((el) => ({
        id: el.id,
        txIndex: el.txIndex,
        key: String(el.id),
        roundId: el.investmentRoundId,
        company: el.company,
        email: el.email,
        phone: el.phoneNumber,
        hardCap: el.hardCap,
        walletAddress: el.walletAddress,
        isConfirmed: false,
        executed: false,
        quorum: [],
        name: el.name,
        roundName: '',
        txAddress: el.txAddress,
        status: el.status,
    }));
};
