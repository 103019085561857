import { investorModel } from '../model/investor';
import { privateRoundModel } from '../model/privateRound';
import {
    getInvestorDTO,
    getRoundDTO,
    patchInvestorDTO,
    patchRoundDTO,
    sendInvestorDTO,
    sendRoundDTO,
} from '../types/DTO';
import { IInvestor } from '../types/investor';
import { IPrivateRound } from '../types/privateRound';
import axios from 'axios';

class RequestService {
    async sendRound(data: sendRoundDTO): Promise<number> {
        const response = await axios.post<{ id: number }>(`private-sale`, data);
        return response.data.id;
    }

    async getRounds(): Promise<IPrivateRound[]> {
        const response = await axios.get<getRoundDTO[]>(`private-sale`);

        return privateRoundModel(response.data);
    }

    async patchRounds(data: patchRoundDTO): Promise<unknown> {
        const response = await axios.patch(`private-sale`, data);

        return response;
    }

    async sendInvestor(data: sendInvestorDTO): Promise<{ id: number }> {
        const response = await axios.post<{ id: number }>(`investors`, data);
        return response.data;
    }

    async getInvestor(): Promise<IInvestor[]> {
        const response = await axios.get<getInvestorDTO[]>(`investors`);
        return investorModel(response.data);
    }

    async patchInvestor(data: patchInvestorDTO): Promise<unknown> {
        const response = await axios.patch(`investors`, data);

        return response;
    }
}

export const ApiRequestService = new RequestService();
