import { Button } from 'antd';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../../../config/context';
import { WalletDispatchContext, WalletStateContext } from '../context';
import { walletLogin } from '../reducer';
import { getCurrentWalletData } from '../utiles/wallet';

export const ConnectWallet: React.FC = () => {
    const { isAdmin, address, isValidNetwork } = useContext(WalletStateContext);
    const config = useConfig();
    const dispatch = useContext(WalletDispatchContext);

    const handleLoginWallet = async () => {
        try {
            dispatch(await walletLogin(config));
            // fetch token etc directly in the context
        } catch (err) {
            console.log(err);
        }
    };

    const handleSwitchToValidNetwork = async () => {
        const { provider } = await getCurrentWalletData();
        if (!provider) {
            return;
        }
        await provider.send('wallet_switchEthereumChain', [
            { chainId: config.networkId },
        ]);
        dispatch(await walletLogin(config));
    };

    return (
        <>
            {address && !isValidNetwork && (
                <Button type="primary" onClick={handleSwitchToValidNetwork}>
                    <FormattedMessage id="switchNetwork" />
                </Button>
            )}
            {!address && (
                <Button type="primary" onClick={handleLoginWallet}>
                    <FormattedMessage id="connectToWallet" />
                </Button>
            )}
            {address}
            {isAdmin ? (
                <FormattedMessage id="Authorized" />
            ) : (
                <FormattedMessage id="Unauthorized" />
            )}
        </>
    );
};
