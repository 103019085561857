import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'antd/dist/antd.css';

Sentry.init({
    dsn: 'https://fd5cc8cb26bc4e169bf86c774e6b2e6c@o1097380.ingest.sentry.io/6247744',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    denyUrls: ['localhost'],
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
